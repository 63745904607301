<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <!--<div class="main-logo">
        <router-link
          :to="{ name: 'main' }"
          class="d-flex align-center">
          <v-img
            :src="appLogo"
            max-height="100px"
            max-width="100px"
            alt="logo"
            contain
            class="me-3 "></v-img>
        </router-link>
      </div>-->
      <v-card class="auth-card card-custom-border-color">
        <!-- logo -->
        <!--<v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            :to="{ name: 'main' }"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>-->

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welcome to Material Trader!
          </p>
          <p class="mb-2">
            Please sign-in to your account
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
          >
            <p
              v-if="errorText"
              class="error--text font-weight-semibold"
            >
              {{ errorText }}
            </p>

            <v-text-field
              v-model="email"
              outlined
              color="alternate"
              placeholder="john@example.com"
              :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              color="alternate"
              :type="isPasswordVisible ? 'text' : 'password'"
              placeholder="Password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :error-messages="errorMessages.password"
              :rules="[validators.required]"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                color="alternate"
                v-model="rememberMe"
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <router-link
                :to="{name:'auth-forgot-password'}"
                class="mt-1 link-color"
              >
                Forgot Password?
              </router-link>
            </div>

            <v-btn
              block
              color="alternate"
              type="submit"
              class="mt-6"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            New on our platform?
          </span>
          <router-link :to="{name:'auth-register'}" class="link-color">
            Create an account
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <!--<img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >-->

    <!-- tree -->
    <!--<v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>-->

    <!-- tree  -->
    <!--<v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>-->
  </div>
</template>

<script>
import { useRouter } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import { emailValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'

import store from '@/store'
import { getCurrentInstance, ref } from 'vue'
import authuserStoreModule from './apps/authuser/authuserStoreModule'

export default {
  setup() {
    const errorText = ref(null)
    const rememberMe = ref(true)

    const AUTHUSER_APP_STORE_MODULE_NAME = 'app-authuser'

    // Register module
    if (!store.hasModule(AUTHUSER_APP_STORE_MODULE_NAME)) {
      store.registerModule(AUTHUSER_APP_STORE_MODULE_NAME, authuserStoreModule)
    }

    // UnRegister on leave
    /* onUnmounted(() => {
      if (store.hasModule(AUTHUSER_APP_STORE_MODULE_NAME)) store.unregisterModule(AUTHUSER_APP_STORE_MODULE_NAME)
    }) */

    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])

    const fetchUserInfo = async user => {
      await store
        .dispatch('app-authuser/fetchUserInfo', {
          user,
        })
        .then(uinfo => {
          if (uinfo.info.admin) {
            uinfo.user.ability = [
              {
                action: 'read',
                subject: 'Admin',
              },
              {
                action: 'read',
                subject: 'Public',
              },
            ]
          } else {
            uinfo.user.ability = [
              {
                action: 'read',
                subject: 'Public',
              },
            ]
            if (uinfo.user.emailVerified) {
              uinfo.user.ability.push({
                action: 'read',
                subject: 'Editor',
              })
            }
          }
          // If user is deleted
          if (uinfo.info.deleted) {
            errorText.value = 'Oops, Unable to login!'
            errorMessages.value = "User Account has been deleted"

            return
          }

          const { ability: userAbility } = uinfo.user

          localStorage.setItem('accessToken', uinfo.user.accessToken)
          localStorage.setItem('userData', JSON.stringify(uinfo))

          // Set user ability
          vm.$ability.update(userAbility)

          // Set user's ability in localStorage for Access Control
          localStorage.setItem('userAbility', JSON.stringify(userAbility))

          delete uinfo.user.ability

          // On success redirect to home
          router.push('/')
        })
        .catch(err => {
          console.log(err.message)
          errorText.value = err.message
          errorMessages.value = err.message
        })
    }

    const handleFormSubmit = async () => {
      errorText.value = null
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */

      await store
        .dispatch('app-authuser/logIn', {
          email: email.value,
          password: password.value,
        })
        .then(user => {
          fetchUserInfo(user)
        })
        .catch(err => {
          console.log(err)
          errorText.value = 'Oops, Unable to login!'

          // console.error()
          // console.log('error :>> ', err.message)
          errorMessages.value = err.message
        })
    }

    return {
      handleFormSubmit,
      isPasswordVisible,
      email,
      password,
      errorMessages,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },
      loginForm,
      rememberMe,
      errorText,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

.auth-wrapper.auth-v1 {
  min-height: calc(var(--vh, 1vh) * 75);
}
.main-logo {
  margin: 0 auto;
  display: table;
  padding: 0 0 20px 0px;
}
.link-color {
  color: #caa227;
}
.card-custom-border-color {
  border: 1px solid #caa227 !important;
}
.v-text-field--outlined {
  border: thin solid var(--v-alternate-base);
  border-color: var(--v-alternate-base);
  color: var(--v-alternate-base);
  caret-color: var(--v-alternate-base);
}
</style>
